import { styles } from '../constants/styles';
import React, {useState, useEffect} from 'react';
import { Text, View, TouchableOpacity, Dimensions } from 'react-native';
import { Label, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine, ReferenceArea, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import {StatefulInput} from 'baseui/input';
import {DatePicker, StatefulCalendar} from 'baseui/datepicker';
import {FormControl} from 'baseui/form-control';
import {TimePicker} from 'baseui/timepicker';
import {addDays, parseISO} from 'date-fns';
import { ArrowRight, ArrowLeft, ArrowUp, ArrowDown, Show } from "baseui/icon";

export default function DataChart(props) {

  const [state, setState] = useState({
    //data: props.data,
    offset: 2,
    thresholdLine: {"upper": props.thresh.high, "lower": props.thresh.low},
    left : 'dataMin',
    right : 'dataMax',
    refAreaLeft : '',
    refAreaRight : '',
    top : 'dataMax+2',
    bottom : 'dataMin-2',
    initialTop: null,
    initialBottom: null,
    //xAxisUnits: initialXAxisUnit,
    animation : true,
    plotData: [],
    rangeDate: [new Date(props.admitted), new Date(props.latestVitals)],
  });

  const getAxisYDomain = (data, from, to, ref, offset) => {
    //const refData = props.data.slice(from-1, to);
    const refData = data.slice(from-1, to);

    let [ bottom, top ] = [ refData[0][ref], refData[0][ref] ];
    refData.forEach( d => {
      if ( d[ref] > top ) top = d[ref];
      if ( d[ref] < bottom ) bottom = d[ref];
    });

    return [ bottom - offset, top + offset ]
  };


  useEffect(() => {
    const newPlotData = selectData(state.refAreaLeft, state.refAreaRight)
    /*
    const [initialBottom, initialTop] = getAxisYDomain(newPlotData, 1, newPlotData.length, 'value', offset)

    setState({...state, plotData: newPlotData, top : initialTop + offset,
        bottom : initialBottom - offset, initialTop, initialBottom});
    */
    let offset = 2;
    let thresholdLine = {"upper": props.thresh.high, "lower": props.thresh.low}
    let yAxisLimit = {"upper": 240, "lower":30}
    switch(props.label){
      case 'BPM':
        offset = 10
        break;
      case '%SPO2':
        offset = 2
        yAxisLimit = {"upper": 105, "lower":50}
        break;
      case 'BrPM':
        offset = 2
        yAxisLimit = {"upper": 60, "lower":0}
        break;
      case 'Temp \u2103':
        yAxisLimit = {"upper": 45, "lower":20}
        break;
      case 'Temp \u2109':
        offset = 4
        yAxisLimit = {"upper": 115, "lower":70}
        thresholdLine.upper = parseInt(((props.thresh.high * (9/5)) + 32).toFixed(1));
        thresholdLine.lower = parseInt(((props.thresh.low * (9/5)) + 32).toFixed(1));
        break;
    }
    console.log(props.label, offset)
    setState({...state, offset, thresholdLine, plotData: newPlotData, top : yAxisLimit.upper + offset,
      bottom : yAxisLimit.lower - offset, initialTop: yAxisLimit.upper, initialBottom: yAxisLimit.lower});

  },[props.data]);

  function setTimeline(date){
    setState({...state, rangeDate:date, left:Date.parse(date[0]), right:Date.parse(date[1])})
  }




  function zoom(){
  	let { refAreaLeft, refAreaRight, refAreaLeftPos, refAreaRightPos, plotData } = state;
		if ( refAreaLeft === refAreaRight || refAreaRight === '' ) {
    	setState({...state,
      	refAreaLeft : '',
        refAreaRight : '',
        refAreaLeftPos : '',
        refAreaRightPos : '',
      });
    	return;
    }

		// xAxis domain
    let refAreaLeftApplied = refAreaLeft;
    let refAreaRightApplied = refAreaRight;
    let refAreaLeftPosApplied = refAreaLeftPos;
    let refAreaRightPosApplied = refAreaRightPos;
	  if ( refAreaLeft > refAreaRight ){
      refAreaLeftApplied = refAreaRight;
      refAreaRightApplied = refAreaLeft;
      refAreaLeftPosApplied = refAreaRightPos;
      refAreaRightPosApplied = refAreaLeftPos;
    }
    		//[ refAreaLeft, refAreaRight ] = [ refAreaRight, refAreaLeft ];

		// yAxis domain
    const [ bottom, top ] = getAxisYDomain( plotData, refAreaLeftApplied, refAreaRightApplied, 'value', state.offset );
    setState({...state,
        plotData: selectData(refAreaLeftApplied, refAreaRightApplied),
        refAreaLeft : '',
        refAreaRight : '',
        refAreaLeftPos : '',
        refAreaRightPos : '',
        left : refAreaLeftPosApplied,
        right : refAreaRightPosApplied,
        bottom, top,
        //xAxisUnits: newXAxisUnit
      });

  };

	function zoomOut() {
  	//const { data } = state;
    selectData('','');
  	setState({...state,
      plotData : selectData('',''),
      refAreaLeft : '',
      refAreaRight : '',
      refAreaLeftPos : '',
      refAreaRightPos : '',
      left : 'dataMin',
      right : 'dataMax',
      top : state.initialTop + state.offset,
      bottom : state.initialBottom - state.offset,
      //xAxisUnits: initialXAxisUnit
    });

  };

  function shiftDate(change){
    const diff = state.right - state.left
    if(change == 'back'){
      setState({...state, left:state.left - (diff/2), right:state.right - (diff/2)})
    }else {
      setState({...state, left:state.left + (diff/2), right:state.right + (diff/2)})
    }
  };

  function shiftValues(change){
    if(change == 'up'){
      setState({...state, top:state.top + (state.offset/2), bottom:state.bottom + (state.offset/2)})
    }else {
      setState({...state, top:state.top - (state.offset/2), bottom:state.bottom - (state.offset/2)})
    }
  }

  function selectData(refAreaLeft, refAreaRight){
    const pointsToPlot = 500;
    let { plotData } = state;
    let origData = props.data
    
    if(refAreaLeft && refAreaRight){
      origData = origData.slice(plotData[refAreaLeft].idx, plotData[refAreaRight].idx)
    }
    origData = origData.filter((value) => value.value !== 0)
    let numOfPoints = origData.length

    if(numOfPoints < pointsToPlot){
        return origData
    }

    // Reduce number of points plotted to pointsToPlot
    const increment = Math.round(numOfPoints/pointsToPlot)
    let newData = origData.filter((_,idx) => idx % increment == 0)
    //setState({...state, plotData:newData})
    return newData
  };

  const CustomTooltipContent = props => {
    if(!props.payload){
      // we just render the default
      return <DefaultTooltipContent {...props} />;
    }
    
    // payload[0] doesn't exist when tooltip isn't visible
    if (props.payload[0] != null) {
      // mutating props directly is against react's conventions
      // so we create a new payload with the name and value fields set to what we want
      const newPayload = [
        {
          name: 'Value',
          value: props.payload[0].payload.value,
          unit: ' ' + props.unit
        },
        {
          name: 'Date',
          // all your data which created the tooltip is located in the .payload property
          value: props.payload[0].payload.date,
          // you can also add "unit" here if you need it
        },
        {
          name: 'Index from present',
          value: props.payload[0].payload.idx,
          //unit: ' minutes'
        },
        {
          name: 'Device alert',
          value: props.payload[0].payload.alert,
          //unit: ' minutes'
        }

      ];

      // we render the default, but with our overridden payload
      return <DefaultTooltipContent payload={newPayload} />;
    }

    // we just render the default
    return <DefaultTooltipContent {...props} />;
  };

  const tickFormatter = (tick) => moment(tick).format('Do-HH:mm:ss');

  function setReferenceArea(e, mouseMotion){
    if(e){
      if (mouseMotion == 'down'){
        setState({...state, refAreaLeftPos:e.activeLabel, refAreaLeft:e.activeTooltipIndex})
      }else{
        state.refAreaLeft && setState({...state, refAreaRightPos:e.activeLabel, refAreaRight:e.activeTooltipIndex})
      }
    }
  }
  console.log(state.plotData.length)
  return(
    <View style={styles.col}>
      <View style={[styles.row, {justifyContent:'space-around'}]}>
        <View style={styles.row}>
          <FormControl label="Date Range" caption="YYYY/MM/DD">
            <DatePicker range value={state.rangeDate} onChange={({date}) => setTimeline(date)}
              placeholder="YYYY/MM/DD – YYYY/MM/DD" minDate={parseISO(props.admitted)} maxDate={parseISO(props.latestVitals)}
              timeSelectStart timeSelectEnd/>
          </FormControl>
        </View>
        <View style={styles.row}>
          <TouchableOpacity style={styles.formBtnSmall} onPress={() => shiftDate('back')}>
              <ArrowLeft size={24} />
          </TouchableOpacity>
          <View style={styles.col}>
            <TouchableOpacity style={styles.formBtnSmall} onPress={() => shiftValues('up')}>
                <ArrowUp size={24} />
            </TouchableOpacity>
            <TouchableOpacity style={styles.formBtnSmall} onPress={() => zoomOut()}>
                <Show size={24} />
            </TouchableOpacity>
            <TouchableOpacity style={styles.formBtnSmall} onPress={() => shiftValues('down')}>
                <ArrowDown size={24} />
            </TouchableOpacity>
          </View>
          <TouchableOpacity style={styles.formBtnSmall} onPress={() => shiftDate('forward')}>
              <ArrowRight size={24} />
          </TouchableOpacity>
        </View>
      </View>

      <View style={[styles.row, {justifyContent:'space-around'}]}>


        <View style={styles.plot}>
          <ResponsiveContainer>
            <LineChart  data={state.plotData} margin={{ top: 15, right: 20, left: 20, bottom: 30 }}
            onMouseDown = { (e) => setReferenceArea(e, 'down') }
            onMouseMove = { (e) => setReferenceArea(e, 'up') }
            onMouseUp = { zoom.bind( this ) }>
             <Line type="monotone" dataKey="value" stroke="#8884d8" isAnimationActive={false}/>
             <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
             <XAxis allowDataOverflow={true} dataKey={"timeValue"} domain={[state.left, state.right]}
              label={{ value: "Time (DD HH:mm:ss)", offset: 0, position: 'bottom' }} type="number"
              scale={"time"}  tickFormatter={tickFormatter} />
             <YAxis allowDataOverflow={true} domain={[state.bottom, state.top]} label={{ value: props.label, angle: -90, position: 'left' }} />
             <Tooltip label={'Data'} content={<CustomTooltipContent unit={props.label}/>}/>
             <ReferenceLine y={state.thresholdLine.upper} label="High" stroke="red" strokeDasharray="3 3" />
             <ReferenceLine y={state.thresholdLine.lower} label="Low" stroke="red" strokeDasharray="3 3" />

             {
              (state.refAreaLeft && state.refAreaRight) ? (
               <ReferenceArea x1={state.refAreaLeftPos} x2={state.refAreaRightPos}  strokeOpacity={0.3} /> ) : null

             }
            </LineChart>
          </ResponsiveContainer>
        </View>
      </View>
    </View>
  )
}
